
import { Component, Mixins } from 'vue-property-decorator';
import { GridTable } from '@/mixins/grid-table';
import { covidForm } from '@/config/forms/covid';
import moment from 'moment';
import { sleep } from '@/config/lib';

@Component({})
export default class SchedulesAdmin extends Mixins<GridTable>(GridTable) {
  modalDetail = false;
  covidDetail = false;
  reportDetail = false;
  activitiesDetail = false;
  deleteDialog = false;
  covidForm = covidForm;
  datePicker = false;
  path = 'schedules';
  item: any = {};
  techs: any[] = [];
  projects: any[] = [];
  activities: any = {};
  reportFilters = {
    initDate: null,
    endDate: null,
    userId: null,
    project: null,
  };
  headers = [
    {
      text: 'Técnico',
      value: 'userId',
    },
    {
      text: 'Correo electrónico',
      value: 'userId.email',
    },
    {
      text: 'Proyecto',
      value: 'projectId.name',
    },
    {
      text: 'Hora de ingreso',
      value: 'checkIn',
    },
    {
      text: 'Hora de salida',
      value: 'checkOut',
    },
    {
      text: 'Registro de actividades',
      value: 'activities',
    },
    {
      text: 'Información de covid',
      value: 'covidForm',
      sortable: false,
    },
    {
      text: 'Detalle',
      value: 'actions',
      sortable: false,
    },
  ];

  filterUser(item: any, queryText: string) {
    const name = item.name.toLowerCase();
    const lastname = item.lastname.toLowerCase();
    const query = queryText.toLowerCase();
    return name.indexOf(query) > -1 ||
      lastname.indexOf(query) > -1;
  }

  async openActivities(item: any) {
    this.activities = {};
    this.setLoader(true);
    try {
      const req: any = await this.$http.get(`/activities`, {
        params: {
          schedule: item._id,
        },
      });
      if (req.data.docs.length) {
        this.activities = req.data.docs[0];
        this.activitiesDetail = true;
      } else {
        throw new Error('No hay actividades');
      }
    } catch (e) {
      this.alert('El usuario no registra actividades');
    }
    this.setLoader(false);
  }

  async openDetailInfo(item: any, type: number) {
    this.setLoader(true);
    this.item = {};
    await sleep(1000);
    this.item = item;
    if (type === 1) {
      this.modalDetail = true;
    } else if (type === 2) {
      this.covidDetail = true;
    } else {
      this.deleteDialog = true;
    }
    this.setLoader(false);
  }

  async getTechs() {
    try {
      const req: any = await this.$http.get('/users', {
        params: {
          rows: 20,
          page: 1,
        },
      });
      this.techs = req.data.docs;
    } catch (e) {
      this.alert('Hubo un error interno', 'error');
    }
  }

  async getProjects() {
    try {
      const req: any = await this.$http.get('/projects/all');
      this.projects = req.data;
    } catch (e) {
      this.alert('Hubo un error interno', 'error');
    }
  }

  async filterItems() {
    this.filters.page = 1;
    await this.listItems();
  }

  async deleteItem(id: string) {
    try {
      await this.$http.delete(`/schedules/${id}`);
      this.deleteDialog = false;
      this.alert('Eliminado correctamente', 'success');
      await this.filterItems();
    } catch (e) {
      this.alert('Hubo un error interno', 'error');
    }
  }

  async generateReport() {
    if (moment(this.reportFilters.endDate).diff(this.reportFilters.initDate, 'd') >= 300) {
      this.alert('El reporte supera los 300 días', 'error');
      return;
    }
    this.setLoader(true);
    try {
      const link = document.createElement('a');

      const req: any = await this.$http.get('/schedules/report/generate', {
        params: {
          initDate: moment(this.reportFilters.initDate).toISOString(),
          endDate: moment(this.reportFilters.endDate).add(1, 'd').toISOString(),
          userId: this.reportFilters.userId,
          projectId: this.reportFilters.project,
        },
      });
      link.setAttribute('href', `${process.env.VUE_APP_API_URL}/${req.data.url}`);
      link.setAttribute('download', req.data.url);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      this.alert('Hubo un error interno', 'error');
    }
    this.setLoader(false);
  }

  async mounted() {
    this.$addScriptMap();
    await this.getTechs();
    await this.getProjects();
    this.getLocalStorage();
    await this.listItems();
  }
}
