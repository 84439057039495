const required = (v: any) => !!v || 'El campo es requerido';
export const covidForm = [
  {
    label:
      '¿Ha estado en contacto en los últioms 10 días con alguien que presenta síntomas de COVID-19?',
    type: 'check-y-n',
    rules: [required],
    field: 'A',
    format: 'col-12',
  },
  {
    label:
      '¿Ha estado en contacto con alguien que tenga prueba POSITIVA por COVID-19?',
    type: 'check-y-n',
    rules: [required],
    field: 'B',
    format: 'col-12',
  },
  {
    label: '¿Ha sido diagnosticado con COVID-19 en los últimos 15 días?',
    type: 'check-y-n',
    rules: [required],
    field: 'C',
    format: 'col-12',
  },
  {
    label: '¿Presenta alguno de estos síntomas?',
    type: 'v-list-item-title',
    format: 'col-12',
  },
  {
    label: 'Diarrea',
    type: 'check-y-n',
    rules: [required],
    field: 'D',
    format: 'col-12',
  },
  {
    label: 'Tos',
    type: 'check-y-n',
    rules: [required],
    field: 'E',
    format: 'col-12',
  },
  {
    label: 'Dolor de garganta',
    type: 'check-y-n',
    rules: [required],
    field: 'F',
    format: 'col-12',
  },
  {
    label: 'Fatiga o decaimiento',
    type: 'check-y-n',
    rules: [required],
    field: 'G',
    format: 'col-12',
  },
  {
    label: 'Vómito',
    type: 'check-y-n',
    rules: [required],
    field: 'H',
    format: 'col-12',
  },
  {
    label: 'Dificultad para respirar',
    type: 'check-y-n',
    rules: [required],
    field: 'I',
    format: 'col-12',
  },
  {
    label: 'Disminución de olfato',
    type: 'check-y-n',
    rules: [required],
    field: 'J',
    format: 'col-12',
  },
  {
    label: 'Disminución del sentido del gusto',
    type: 'check-y-n',
    rules: [required],
    field: 'K',
    format: 'col-12',
  },
  {
    label: 'Malestar general',
    type: 'check-y-n',
    rules: [required],
    field: 'L',
    format: 'col-12',
  },
  {
    label: 'Registre su temperatura',
    type: 'v-text-field',
    mode: 'number',
    rules: [required],
    field: 'M',
    format: 'col-12',
  },
];
